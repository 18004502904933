@import '~antd/dist/antd.less';
@font-face {
  font-family: 'Lato';
  src: local('Lato'), url(./fonts/Lato/Lato-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-weight: 900;
  src: local('Lato'), url(./fonts/Lato/Lato-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-weight: 500;
  src: local('Lato'), url(./fonts/Lato/Lato-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-weight: 600;
  src: local('Lato'), url(./fonts/Lato/Lato-Semibold.ttf) format('truetype');
}

@font-family: Lato, sans-serif;@primary-color: #f8e800;@text-color: #0d0d0d;@font-size-base: 14px;@border-radius-base: 4px;@border-color-base: #e6e6e6;@height-base: 40px;@height-lg: 48px;@height-sm: 32px;@checkbox-color: #f8e800;@checkbox-check-color: #000;@checkbox-size: 16px;@checkbox-check-bg: #fff;@checkbox-border-width: 1px;@checkbox-group-item-margin-right: 8px;@table-expanded-row-bg: #000;@table-header-bg: #fff;@table-selected-row-bg: #fefde6;@table-row-hover-bg: #fefde6;@pagination-item-size: @height-base;@pagination-item-size-sm: 24px;@pagination-font-family: @font-family;@pagination-font-weight-active: 500;@pagination-item-bg-active: @primary-color;@pagination-item-disabled-bg-active: darken(@disabled-bg, 10%);@pagination-mini-options-size-changer-top: 0px;@btn-shadow: none;@btn-primary-shadow: none;@btn-text-shadow: none;@btn-primary-color: #0d0d0d;@btn-default-color: @btn-primary-color;@btn-link-hover-bg: none;@btn-font-size-lg: 14px;@btn-font-size-sm: 12px;@btn-primary-bg: #f8e800;@btn-default-bg: #fff;@btn-height-base: @height-base;@btn-height-lg: @height-lg;@btn-height-sm: @height-sm;@btn-padding-horizontal-base: 24px;@btn-padding-horizontal-lg: 28px;@btn-padding-horizontal-sm: 20px;@btn-text-hover-bg: #595959;@select-border-color: #e6e6e6;@picker-basic-cell-active-with-range-color: rgba(248, 232, 0, 0.3);@picker-basic-cell-hover-with-range-color: #f8e800;@picker-basic-cell-disabled-bg: transparent;@input-height-base: @height-base;@input-height-lg: @height-lg;@input-height-sm: @height-sm;@input-padding-horizontal-base: 16px;@input-placeholder-color: #92929d;@input-border-color: #e6e6e6;@input-hover-border-color: #1066f2;@input-disabled-bg: rgba(255, 255, 255, 0.5);@input-disabled-color: rgba(146, 146, 157, 0.5);