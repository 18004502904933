body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}

::-webkit-scrollbar:horizontal {
  height: 16px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}

::-webkit-scrollbar-thumb {
  background-color: #92929d;
  border-radius: 2px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.01);
  background-color: rgba(0, 0, 0, 0.1);
}

input::placeholder,
.ant-select-selection-placeholder {
  color: #343a40 !important;
}

.ant-select-suffix svg,
.ant-picker-suffix svg {
  fill: #343a40 !important;
}

.ant-checkbox-inner {
  border-color: #343a40 !important;
}
