.Header {
  background-color: #2b2d2d;
  padding-left: 48px;
  padding-right: 48px;
  height: 56px;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.BaseLayoutHeaderNavigation {
  padding-left: 24px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: 0.83px;
  color: #fff;
  text-decoration: none;
}

.HeaderIcon {
  padding-right: 48px;
}

.LogOut {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.LogOutIcon {
  cursor: pointer;
}

.Profile {
  color: #fff;
  padding-top: 6px;
  padding-right: 24px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: 0.83px;
  text-decoration: none;
}

.BaseLayoutHeaderNavigation:hover {
  color: #f8e800;
  cursor: pointer;
}

.Content {
  background-color: #f6f6fb;
  height: calc(100vh - 55px);
  color: #000 !important;
  overflow-y: auto;
}

.UserWrapper {
  padding-right: 16px;
  color: #fff;
}

.KZTheme .Header {
  background: #12427a;
}

.KZTheme .HeaderIcon {
  height: 32px;
}

.KZTheme .HeaderIcon svg {
  height: 100% !important;
}

.KZTheme .LogOut {
  opacity: 0.7;
}

.ActionIconWrapper {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-tooltip {
  max-width: 300px !important;
}

.ActionIconWrapper:hover {
  background: #e9ecef;
}

.ActionIcon {
}
