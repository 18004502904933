.LoginWrapper {
  width: 100vw;
  height: 100vh;
  background-color: #2b2d2d;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.LoginWrapperIcon {
  padding-top: 44px;
  padding-bottom: calc((100vh - 600px) / 2);
}

.LoginFormWrapper {
  border-radius: 16px;
  box-shadow: 0 25px 50px 0 rgba(75, 75, 77, 0.15);
  border: solid 1px #e6e6e6;
  background-color: #fff;
  padding: 46px 76px;
  width: 504px;
}

.LoginFormLabel {
  width: 360px;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: #0d0d0d;
  padding-bottom: 38px;
}

.LoginFormFieldWrapper {
  display: grid;
  place-items: center;
}

.LoginFormField {
  width: 240px;
  padding-top: 8px;
}

.ErrorMessage {
  display: grid;
  place-items: center;
  color: #ff0025;
  padding: 5px 0;
}

.LoginFormButtons {
  display: grid;
  place-items: center;
}

.LocalSwitcher {
  display: flex;
  flex-direction: column;
  padding-left: 8px;
  color: rgb(133, 133, 133);
}

.LocalSwitcher span:hover {
  cursor: pointer;
  color: #f8e800;
}

.LocalActiveRu {
  color: #ff0126 !important;
}

.LocalActiveKz {
  color: #f8e800 !important;
}

.LoginFormEnter {
  width: 240px;
  height: 48px !important;
  color: #0d0d0d !important;
}

.LoginFormEnter:hover {
  background-color: #ebdb00 !important;
  border-color: #ebdb00 !important;
}

.LoginWrapper-kz {
  background: radial-gradient(50% 50% at 50% 50%, #0a3d7a 0%, #0c2747 100%);
}

.LoginWrapper-kz .LoginFormWrapper {
  background: #2063b2;
  border-color: #2063b2;
}

.LoginWrapper-kz .LoginWrapperIcon {
  padding-top: 80px;
}

.LoginWrapper-kz .LoginFormLabel {
  color: #fff;
}

.LoginWrapper-kz .LoginFormEnter {
  background: #f8e800;
}

.LoginWrapper-kz .LoginFormField .ant-input {
  background: transparent;
  color: #fff;
}

.LoginWrapper-kz .LoginFormField .anticon {
  color: #fff;
}

.LoginWrapper-kz .LoginFormField .ant-input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.LoginWrapper-kz .LoginFormField > .ant-input,
.LoginWrapper-kz .LoginFormField .ant-input-password {
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.4);
}

.LoginWrapper-kz .LoginFormField > .ant-input,
.LoginWrapper-kz .LoginFormField .ant-input-password {
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.4);
}

.LoginWrapper-kz .LoginFormField > .ant-input:focus,
.LoginWrapper-kz .LoginFormField .ant-input-affix-wrapper-focused {
  border-color: #fff !important;
  box-shadow: none !important;
}
