.RowWrapper {
  display: flex;
}

.AddPhonesComponentWrapper {
  width: 85%;
}

.AddPhonesFormWrapper {
  width: 100%;
  border-radius: 8px;
  border: solid 1px rgba(179, 179, 179, 0.3);
  padding: 16px 32px 32px 32px;
  height: 420px;
  overflow-y: auto;
}

.Rectangle {
  margin-top: 18px;
}

.AddPhonesRectangle {
  padding-top: 10px;
  padding-right: 16px;
  line-height: 1.45;
  letter-spacing: 0.4px;
  color: #343a40;
  font-size: 11px;
}

.FieldLabel {
  font-size: 11px;
  font-weight: bold;
  line-height: 1.45;
  letter-spacing: 0.4px;
  color: #343a40;
  padding-top: 16px;
}

.addBlacklistModal .ModalBody {
  width: 1096px !important;
  max-width: 95%;
}

.addBlacklistModal .ModalContentWrapper {
  padding-top: 0;
  font-family: "Gilroy", sans-serif;
  font-style: normal;
  color: #212529;
}

.addBlacklistModal .ModalHeader {
  text-align: left;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 0;
  width: 100%;
}

.addBlacklistModal .AddPhonesFormWrapper {
  border: none;
  padding: 0;
  height: auto;
  min-height: 470px;
}

.addBlacklistModal .AddPhonesComponentWrapper {
  width: 100%;
  padding: 0 40px;
}

.addBlacklistModal .FieldLabel {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #212529;
  margin-bottom: 6px;
  padding-top: 25px;
}

.addBlacklistModal
  .ant-select-show-search.ant-select-multiple
  .ant-select-selector,
.addBlacklistModal textarea.ant-input {
  height: 80px;
  border: none;
  border-radius: 8px;
  box-shadow: 0 0 0 1px #495057 inset !important;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  background-color: #fff;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.addBlacklistModal .ant-select-dropdown {
  display: none !important;
}

.addBlacklistModal .ant-select-selection-search {
  margin-top: 4px;
  margin-bottom: 4px;
}

.addBlacklistModal .ant-select-multiple .ant-select-selection-placeholder {
  top: 25%;
  left: 14px;
}

.addBlacklistModal .CheckboxWrapper {
  margin-top: 25px;
  color: #adb5bd;
}

.addBlacklistModal .ButtonPrimary,
.addBlacklistModal .ButtonDefault {
  width: 200px;
  font-weight: 600;
  font-size: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  height: auto;
  border-radius: 8px;
}

.addBlacklistModal
  .ant-select-show-search.ant-select-multiple.EmptyInput
  .ant-select-selector,
.addBlacklistModal .EmptyInput textarea.ant-input {
  background-color: #f8f9fa !important;
  box-shadow: 0 0 0 1px #ced4da inset !important;
}

.addBlacklistModal
  .ant-select-multiple
  .ant-select-selection-item-remove
  > .anticon {
  vertical-align: -0.1em;
}

.addBlacklistDupWarningModal .ModalWrapper {
  width: 100%;
  height: 100%;
}

.addBlacklistDupWarningModalInfoWrapper {
  height: 300px;
  width: 90%;
  overflow: auto;
}

addBlacklistDupWarningModalInfoWrapper .ModalHeader {
  text-align: center;
}

.addBlacklistDupWarningModal_club {
  font-weight: 700;
}

.addBlacklistDupWarningModalRowWrapper {
  margin-bottom: 10px;
}

.SelectClubsWrapper {
  width: 100%;
}
